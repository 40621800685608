export const faqText = [
    {
        question: 'How much can I earn in a month?',
        answer: 'Earnings vary based on location, availability, rates, and the types of bookings you can accommodate.\n\nIn Queens, New York, monthly earnings range from $150 to $400. Hosts accommodating hourly or daily bookings typically earn more.',
    },
    {
        question: 'How long does it take to sign up?',
        answer: 'Drivers can create an account, add car details, add a payment method, and start reserving parking immediately.\n\nHosts must submit details about their space and verify their identity. The onboarding process is simple and quick. Once approved, owners can begin receiving reservations for their space.',
    },
    {
        question: 'How long does it take for my driveway/garage to get approved?',
        answer: 'Applying only takes a few minutes, and approval typically takes 1-3 days.\n\nParket reviews your application details within a few hours. Our payment partner, {link1}, verifies your identity and bank information in as little as a few minutes, but it can take up to 3 days.\n\nIf your application is denied, you may reapply at any time. If you are already verified by Stripe, you only have to wait a few hours for Parket to re-review.',
        links: [{ placeholder: 'Stripe', link: 'https://stripe.com/' }],
    },
    {
        question: 'Is my payment secure?',
        answer: `All transactions are securely handled by Stripe, a trusted third-party payment processor. For more information, visit {link1}.`,
        links: [{ placeholder: 'stripe.com', link: 'https://stripe.com/' }],
    },
    {
        question: 'How quickly can I book a spot?',
        answer: 'Spots accepting instant reservations can be booked immediately. Non-instant reservation spots require the host to approve your request.',
    },
    {
        question: 'Can I cancel my booking or request?',
        answer: 'Renters can cancel at any time. Only cancelations made before the grace-period or booking start time are eligible for any refunds. \n\nHosts can cancel up to 15 minutes before a booking ends. Affected renters will receive a full refund.\n\nFor more details on refunds and cancelations, check out the {link1} and {link2} refund policies.',
        links: [
            { placeholder: 'renter', link: 'https://joinparket.com/terms-and-conditions#section5' },
            { placeholder: 'host', link: 'https://joinparket.com/terms-and-conditions#section10' },
        ],
    },
    {
        question: 'How do I delete my account and how is my data handled?',
        answer: (
            <>
                <p>
                    <span style={{ textDecorationLine: 'underline' }}>Step 1</span>: Use the contact form below and select "Request Account Deletion" (or email directly).
                </p>
                <p>
                    <span style={{ textDecorationLine: 'underline' }}>Step 2</span>: We will confirm within 24 hours or ask for more details to identity your account.
                </p>
                <p>
                    <span style={{ textDecorationLine: 'underline' }}>Step 3</span>: Your account and user data will be deleted in 24-48 hours.
                </p>
                <br />
                <p>All personal information and data you provided will be deleted.</p>
                <p>
                    Refer to our{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="privacy-policy#section2"
                        style={{ color: '#fff', textDecorationLine: 'underline' }}>
                        Privacy Policy
                    </a>{' '}
                    for more details.
                </p>
            </>
        ),
    },
]
