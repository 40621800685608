import './App.css'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Component Imports
import Navbar from './components/NavBar/Navbar'
import Landing from './components/Landing/Landing'
import Waitlist from './components/Waitlist/Waitlist'
import About from './components/About/About'
import Footer from './components/Footer/Footer'
import Return from './components/Return/Return'
import Terms from './components/Terms/Terms'
import Privacy from './components/Privacy/Privacy'
import Help from './components/Help/Help'

// Starts the page at the top whenever we route
import ScrollToTop from './scrollToTop'

function App() {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768)

    // Listener to check for mobile window size
    React.useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Router>
            <ScrollToTop />
            <div className="app">
                <Navbar isMobile={isMobile} />
                <Routes>
                    <Route
                        path="/"
                        element={<Landing />}
                    />
                    <Route
                        path="/waitlist"
                        element={<Waitlist />}
                    />
                    <Route
                        path="/about"
                        element={<About />}
                    />
                    <Route
                        path="/help"
                        element={<Help isMobile={isMobile} />}
                    />
                    <Route
                        path="/terms-and-conditions"
                        element={<Terms />}
                    />
                    <Route
                        path="/privacy-policy"
                        element={<Privacy />}
                    />
                    <Route
                        path="/return"
                        element={<Return />}
                    />
                </Routes>
                <Footer />
            </div>
        </Router>
    )
}

export default App
