import React from 'react'
import './Return.css'
import returnLogo from '../../assets/app-logo.png'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { IoChatbubbleEllipses } from 'react-icons/io5'

export default function Return() {
    const handleClick = () => {
        // This doesn't do anything unless in a dev/prod build
        window.location.href = 'parket://'
    }

    return (
        <div className="return-page-container">
            <img
                className="return-logo"
                src={returnLogo}
                alt="app-logo"
            />
            <div className="return-content">
                <div>
                    <IoIosCheckmarkCircle className="return-icon" />
                    <h1> Thanks for completing your Stripe Onboarding!</h1>
                    <h3> Continue in-app for more details</h3>
                </div>

                <div>
                    <h2> Issues or questions?</h2>
                    <div className="return-content-contact">
                        <MdEmail className="return-contact-icon" />
                        <p className="return-contact-text">support@joinparket.com</p>
                    </div>
                    <div className="return-content-contact">
                        <IoChatbubbleEllipses className="return-contact-icon" />
                        <p className="return-contact-text">{`(929) 595-7275`}</p>
                    </div>
                </div>
            </div>
            <div>
                <button
                    className="return-to-app-btn"
                    onClick={handleClick}>
                    Return to App
                </button>
            </div>
        </div>
    )
}
