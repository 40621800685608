// 1. Introduction
export const privacyIntro_p1 = `Parket ("us", "we", or "our"), trading as Sloanie Inc., is committed to protecting the privacy and security of our users' information. This Privacy Policy outlines the types of personal information we collect from you when you use Parket, how we use it, the circumstances under which we share it with third parties, and how we keep it secure. Our goal is to provide you a seamless experience while respecting your privacy and upholding the trust you place in us.`

export const privacyIntro_p2 = `When you use Parket to list or rent a driveway or garage, you share information that helps us provide our services efficiently and responsibly. We understand the importance of this information and take significant steps to protect it. This policy also describes your rights regarding the information you provide to us and how you can access and update this information.`

export const privacyIntro_p3 = `Please read this policy carefully to understand our practices regarding your personal data. By using our services, you agree to the collection, use, and sharing of your data as described in this privacy policy. If you have any questions or concerns about our use of your personal information, please contact us at the details provided at the end of this document.`

// 2. Personal Information We Collect
export const personalInfoHeader = `We collect various types of personal information to provide and improve our services.`

export const personalInfo2_1 = `For all users: We collect your first and last name, email address, and optionally, a phone number and an image to personalize your account experience.`

export const personalInfo2_2 = `For Renters we require details about your vehicle such as the make, model, year, color, and license plate number to ensure proper identification and security during the rental process.`

export const personalInfo2_3 = `We collect payment details including credit card numbers and bank account information to process transactions securely. This information may be directly managed by our payment processors, such as Stripe, to enhance security.`

export const personalInfo2_4 = `Our third party payment processor, Stripe, collects information related to your identity, which includes your name, email address, phone number, physical address, and social security number, which are necessary for identity verification and compliance with legal requirements.`

export const personalInfo2_5 = `We collect information about your location when you use our search functionality to help you find nearby parking spaces effectively. For Hosts, we collect the address of the listed driveway or garage to accurately display your offering on our platform.`

export const personalInfo2_6 = `We keep records of your interactions with our service, such as communication in chats and other usage statistics.`

export const personalInfo2_7 = `We gather information related to the device you use to access our services, such as IP addresses, hardware and software details, event logs, and crash reports, which help us diagnose technical issues and optimize our product performance.`

export const personalInfo2_8 = `Through Stripe, we maintain records of all your transactions processed through Parket, including payments and payouts, to ensure accurate financial reporting and support.`

// 3. How We Use Your Information
export const usageInfoHeader = `We use your information in the following ways:`

export const usageInfo3_1 = `Your account information enables you to log in and access Parket's services, ensuring that you can list and rent spaces efficiently. We use your contact information to enable communication between Renters and Hosts, facilitating a smooth rental process and enhancing user interaction.`

export const usageInfo3_2 = `We utilize your payment and identity information to process transactions securely and efficiently, allowing for seamless payments and financial operations through our platform.`

export const usageInfo3_3 = `To make Parket safer, we use your information to verify user identities, monitor activities for suspicious or fraudulent transactions, and enforce our terms and conditions to protect the interests of all users.`

export const usageInfo3_4 = `We analyze usage information and device data to understand how our services are used. This analysis helps us identify user behavior trends, optimize product design, and improve functionality.`

export const usageInfo3_5 = `We may use your information to tailor marketing and advertising content to your interests, enhancing your overall experience and providing you with relevant offers. In some cases, we might share non-personally identifiable information with advertising partners to conduct marketing campaigns or display ads that are more relevant to your interests.`

export const usageInfo3_6 = `We may use your information to comply with legal obligations, respond to legal requests, and protect our rights in legal proceedings.`

// 4. Information Sharing and Disclosure
export const infoSharingHeader = `At Parket, we are committed to maintaining the confidentiality of your information. However, there are certain circumstances under which we may share your information with third parties or other users to facilitate the operation of our services.`

export const infoSharing4_1 = `We use Stripe as our payment processor to handle transactions securely. In order to process payments effectively, certain information such as your name, payment details, and transaction amounts may be shared with Stripe. We may share your information with third-party service providers who perform services on our behalf, such as data analysis, marketing assistance, email delivery, hosting services, and customer service. These service providers are authorized to use your personal information only as necessary to provide these services to us. We may disclose your information if required by law, such as to comply with a subpoena, or similar legal process when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.`

export const infoSharing4_2 = `To facilitate communication between renters and hosts, we share limited personal information such as your name and image. This information is necessary to help users identify each other and ensure a smooth transaction and communication process. For booking and request purposes, we share relevant personal information like your name, image, and vehicle details (for renters) with the corresponding party (host or renter). This information is essential for the verification and facilitation of the parking arrangement. Hosts' listing details, including the address and any provided descriptions or images of the parking space, are made available to renters through our platform. This information is crucial for renters to make informed decisions about the listings they are interested in.`

export const infoSharing4_3 = `We may share aggregated or anonymized information that does not directly identify you with third parties for industry analysis, demographic profiling, marketing, and other business purposes.`

// 5. Data Storage and Security
export const dataStorageHeader = `Parket takes the security of your data very seriously. We implement a variety of administrative, technical, and physical security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our site.`

export const dataStorage5_1 = `We retain your personal information for as long as your account is active or as needed to provide you services. We will also retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.`

export const dataStorage5_2 = `We strictly limit access to your information to employees and third parties who need to know that information in order to process it on our behalf. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations. We conduct regular security assessments and audits to identify and mitigate risks, ensuring the integrity and security of your personal data. If you know or have reason to believe that your Parket Account credentials have been lost, stolen, misappropriated, or otherwise compromised, or in the case of any actual or suspected unauthorized use of your Parket Account, please contact us immediately using the instructions in the Contact Us section below.`

// 6. Changes to This Privacy Policy
export const changesPolicyHeader = `Parket reserves the right to modify this Privacy Policy at any time as we deem necessary or as required by law. Any modifications will be effective immediately upon posting the updated policy on our website. The “Last Updated” date at the top of this policy will indicate the latest revision.`

export const changesPolicy6_1 = `Should there be material changes to our Privacy Policy, we will notify you in advance by sending a notice to the email address associated with your account at least thirty (30) days before the changes take effect. This notice will allow you to review the changes before they become effective.

If you do not agree with the changes to our Privacy Policy, you have the option to close your Parket account. Continuing to use our services after the effective date of the revised policy will constitute your acceptance of the changes. If you continue to use our services after the changes come into force, it will be under the terms of the updated policy.`

// 7. Contact Information
export const contactInfoHeader = `If you have any questions about this Privacy Policy or our data practices, or if you need assistance with any issues related to your account, please do not hesitate to contact us. We are always available to help you.`

export const contactInfo7 = (
    <>
        You can reach us via email at <a href="mailto:contact@joinparket.com">contact@joinparket.com</a>.
    </>
)
