import React from 'react'
import './Privacy.css'
import bannerCar from '../../assets/banner-car.png'
import {
    privacyIntro_p1,
    privacyIntro_p2,
    privacyIntro_p3,
    personalInfoHeader,
    personalInfo2_1,
    personalInfo2_2,
    personalInfo2_3,
    personalInfo2_4,
    personalInfo2_5,
    personalInfo2_6,
    personalInfo2_7,
    personalInfo2_8,
    usageInfoHeader,
    usageInfo3_1,
    usageInfo3_2,
    usageInfo3_3,
    usageInfo3_4,
    usageInfo3_5,
    usageInfo3_6,
    infoSharingHeader,
    infoSharing4_1,
    infoSharing4_2,
    infoSharing4_3,
    dataStorageHeader,
    dataStorage5_1,
    dataStorage5_2,
    changesPolicyHeader,
    changesPolicy6_1,
    contactInfoHeader,
    contactInfo7,
} from './privacy-text'

export default function Privacy() {
    // The focused section, used by Table of Contents to determine which item to highlight/active style
    const [activeSection, setActiveSection] = React.useState('')

    // Store refs to all sections for Observer
    const sectionRefs = React.useRef(new Map())

    // Handles setting which section we are focused on.
    React.useEffect(() => {
        const observerOptions = {
            root: null,
            // Adjusting to trigger when header is at the topmost part of the screen
            // Currently the section header that is on the top 10% of the screen is the focused section
            rootMargin: '0px 0px -90% 0px',
            threshold: 0,
        }

        // Replace URL section (ex: localhost:3000/terms-and-conditions#section6)
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.id
                    setActiveSection(sectionId)
                    window.history.replaceState(null, '', `#${sectionId}`)
                }
            })
        }

        // Provides a way to asynchronously observe changes in the intersection of a target element with an ancestor element or with a top-level document's viewport.
        const observer = new IntersectionObserver(observerCallback, observerOptions)

        // Observe each section
        sectionRefs.current.forEach((ref) => {
            if (ref) observer.observe(ref)
        })

        // Clean up observer on component dismount
        return () => {
            sectionRefs.current.forEach((ref) => {
                if (ref) observer.unobserve(ref)
            })
        }
    }, [])

    // Scrolls to hashed string if user routed with a hash in the URL (ex: localhost:3000/page#section6)
    React.useEffect(() => {
        const hash = window.location.hash.substring(1)
        if (hash) {
            const element = document.getElementById(hash)
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' })
                setActiveSection(hash)
            }
        }
    }, [])

    // Register all section references to #id
    const registerSectionRef = (sectionId, ref) => {
        sectionRefs.current.set(sectionId, ref)
    }

    return (
        <div className="privacy-wrapper">
            <div className="privacy-banner">
                <h1>Privacy Policy</h1>
                <h2>Sloanie, Inc. DBA Parket</h2>
                <p>Last Updated: Mon September 2, 2024</p>
            </div>

            <div className="privacy-page-container">
                <div className="privacy-table-of-contents">
                    <h2>Table of Contents</h2>
                    <ul>
                        {[
                            { id: 'section1', title: '1. Introduction' },
                            { id: 'section2', title: '2. Personal Information We Collect' },
                            { id: 'section3', title: '3. How We Use Your Information' },
                            { id: 'section4', title: '4. Information Sharing and Disclosure' },
                            { id: 'section5', title: '5. Data Storage and Security' },
                            { id: 'section6', title: '6. Changes to This Privacy Policy' },
                            { id: 'section7', title: '7. Contact Information' },
                        ].map((item) => (
                            <li
                                key={item.id}
                                className={activeSection === item.id ? 'active' : ''}>
                                <a
                                    href={`#${item.id}`}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        document.getElementById(item.id).scrollIntoView({
                                            behavior: 'smooth',
                                            block: 'start',
                                        })
                                    }}>
                                    {item.title}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="privacy-content">
                    <div
                        id="section1"
                        ref={(ref) => registerSectionRef('section1', ref)}
                        className="privacy-section"
                        style={{ padding: '0px 20px' }}>
                        <h2>1. Introduction</h2>

                        <p>{privacyIntro_p1}</p>
                        <br />
                        <p>{privacyIntro_p2}</p>
                        <br />
                        <p>{privacyIntro_p3}</p>
                    </div>
                    <div
                        id="section2"
                        ref={(ref) => registerSectionRef('section2', ref)}
                        className="privacy-section">
                        <h2>2. Personal Information We Collect</h2>
                        <p>{personalInfoHeader}</p>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.1 Account Information</span>
                            <p>{personalInfo2_1}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.2 Vehicle Information</span>
                            <p>{personalInfo2_2}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.3 Payment Information</span>
                            <p>{personalInfo2_3}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.4 Identity Information</span>
                            <p>{personalInfo2_4}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.5 Geolocation Information</span>
                            <p>{personalInfo2_5}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.6 Usage Information</span>
                            <p>{personalInfo2_6}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.7 Device Information</span>
                            <p>{personalInfo2_7}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>2.8 Transaction History</span>
                            <p>{personalInfo2_8}</p>
                        </div>
                    </div>
                    <div
                        id="section3"
                        ref={(ref) => registerSectionRef('section3', ref)}
                        className="privacy-section">
                        <h2>3. How We Use Your Information</h2>
                        <p>{usageInfoHeader}</p>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.1 Service Access and Usage</span>
                            <p>{usageInfo3_1}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.2 Payment Processing</span>
                            <p>{usageInfo3_2}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.3 Security and Fraud Prevention</span>
                            <p>{usageInfo3_3}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.4 Analytics and Service Improvement</span>
                            <p>{usageInfo3_4}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.5 Marketing and Advertising</span>
                            <p>{usageInfo3_5}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>3.6 Legal and Regulatory Compliance</span>
                            <p>{usageInfo3_6}</p>
                        </div>
                        3
                    </div>
                    <div
                        id="section4"
                        ref={(ref) => registerSectionRef('section4', ref)}
                        className="privacy-section">
                        <h2>4. Information Sharing and Disclosure</h2>
                        <p>{infoSharingHeader}</p>
                        <br />
                        <div className="privacy-section-column">
                            <span>4.1 Sharing With Third Parties</span>
                            <p>{infoSharing4_1}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>4.2 Sharing Between Users</span>
                            <p>{infoSharing4_2}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>4.3 Aggregated or Non-Identifiable Data</span>
                            <p>{infoSharing4_3}</p>
                        </div>
                    </div>
                    <div
                        id="section5"
                        ref={(ref) => registerSectionRef('section5', ref)}
                        className="privacy-section">
                        <h2>5. Data Storage and Security</h2>
                        <p>{dataStorageHeader}</p>
                        <br />
                        <div className="privacy-section-column">
                            <span>5.1 Data Retention</span>
                            <p>{dataStorage5_1}</p>
                        </div>
                        <br />
                        <div className="privacy-section-column">
                            <span>5.2 Data Security</span>
                            <p>{dataStorage5_2}</p>
                        </div>
                    </div>
                    <div
                        id="section6"
                        ref={(ref) => registerSectionRef('section6', ref)}
                        className="privacy-section">
                        <h2>6. Changes to This Privacy Policy</h2>
                        <p>{changesPolicyHeader}</p>
                        <br />
                        <p>{changesPolicy6_1}</p>
                    </div>
                    <div
                        id="section7"
                        ref={(ref) => registerSectionRef('section7', ref)}
                        className="privacy-section">
                        <h2>7. Contact Information</h2>
                        <p>{contactInfoHeader}</p>
                        <br />
                        <p>{contactInfo7}</p>
                    </div>
                </div>
            </div>
            <div className="privacy-questions-container">
                <h2>Any questions or concerns? Feel free to contact us at anytime.</h2>
                <a
                    href="mailto:contact@joinparket.com"
                    target="_blank"
                    rel="noreferrer">
                    <h2 style={{ marginBottom: 10, color: '#ffef00' }}>contact@joinparket.com</h2>{' '}
                </a>
                <img
                    alt="banner-car-img"
                    src={bannerCar}
                />
            </div>
        </div>
    )
}
