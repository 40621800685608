// 1. Introduction
export const introductionTextParagraph1 = `Welcome to Parket! These Terms of Service (“Terms”) constitute a binding legal agreement between Parket ("us", "we", or "our"), trading as Sloanie Inc. (a Delaware C Corporation), and you, the user ("you" or "your"). By using or accessing our app in any way, you agree to be bound by these terms. Please read them carefully before proceeding.`
export const introductionTextParagraph2 = `Parket is a mobile application that connects users looking for parking (“Renters”) to users (“Hosts”) who are able to list their driveways, garages, or other property suitable for parking (“Listings”) on our platform. Parket does not own, control, offer, or manage any Listings or associated services. Parket is not a party to the contracts entered into directly between Hosts and Renters, nor is Parket a real estate broker or insurer. Parket is not acting as an agent in any capacity for any user.`
export const introductionTextParagraph3 = `For the purposes of these Terms of Service, "Services" refers to all the functionalities, features, and provisions offered through the Parket platform, including but not limited to the facilitation of discovering, booking, and managing rentals of parking spaces. Parket reserves the right, at its sole discretion, to modify or replace these Terms of Service at any time. By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new Terms, you are no longer authorized to use the Services.`
export const introductionTextParagraph4 = `For any concerns regarding the handling, processing, and storage of your personal data, please refer to our Privacy Policy, which outlines our practices and your rights in relation to your personal information.`

// 2. Renter Terms:
// 2.1. Searching
export const renterTermsText2_1 = `As a Renter on Parket, you can search for parking listings by entering a location name or by browsing through the map on our platform. To refine your search and find listings that meet your specific needs, you can apply various filters, including arrival time, departure time, and car size.`
// 2.2. Listing Information
export const renterTermsText2_2 = `When you select a listing, you can view details provided by the Host, such as the general location of the parking space, features of the space, availability, and pricing. Parket is not responsible for any inaccuracies or misinformation provided in the listings. Parket acts solely as a platform for facilitating transactions between Hosts and Renters. As such, we do not guarantee the accuracy, completeness, or timeliness of information provided by users, nor do we endorse any Hosts. Renters use the service at their own risk.`

// 3. Making a Request & Booking a Listing
// 3.1. Types of Requests and Bookings
export const bookingAndRequests3_1 = `A "Booking" on Parket refers to a contractual agreement initiated by a Renter through the Parket platform and defined by these terms to reserve a specific parking space provided by a Host. Parket offers two primary types of Bookings to accommodate different user needs. “Standard Bookings" are for a defined period with specified arrival and departure times. “Rolling Bookings” allow Renters to start on a defined arrival date and continue the booking indefinitely until cancelation by either the Host or the Renter. Rolling bookings can be broken up into “Weekly Bookings” where Renters are charged on a weekly basis and “Monthly Bookings” where renters are charged on a monthly basis. Hosts decide what type of bookings their Listing can accommodate. Each booking specifies:`
// 3.1. Examples
export const bookingAndRequests3_1_ex_i = `The location of the parking space.`
export const bookingAndRequests3_1_ex_ii = `The duration of the rental period, which may be of a fixed duration as in a Standard. Renters must adhere to these times as agreed upon in the booking.`
export const bookingAndRequests3_1_ex_iii = `Booking or of an indefinite duration as in a Rolling Booking.`
export const bookingAndRequests3_1_ex_iv = `The arrival and departure times (applicable to Standard Bookings).`
export const bookingAndRequests3_1_ex_v = `The billing interval (applicable to Rolling Bookings, either weekly or monthly).`
export const bookingAndRequests3_1_ex_vi = `The agreed-upon rate.`
export const bookingAndRequests3_1_ex_vii = `Any other terms specified by the Host that are compliant with Parket’s policies.`

// 3.2. Making a Request
export const bookingAndRequests3_2 = `A "Request" is the action taken by a Renter to book a Listing on the Parket platform. This action does not grant immediate rights to park but initiates a review process by the Host. A Request encompasses all details required for a Booking, as specified in Section 2.1. Requests require approval from the Host, which can be given directly or set to occur automatically through pre-configured settings by the Host. Hosts retain the right to deny any Request without providing a reason.`
// 3.3. Accepted Request
export const bookingAndRequests3_3 = `Approval of a Request by the Host turns it into a confirmed Booking, at which point the Renter is authorized to use the parking space as per the agreed terms. Renters must adhere to all rules and instructions provided by the Host regarding the use of the parking space. This includes, but is not limited to, respecting designated parking times, vehicle size restrictions, and any specific conditions set by the Host. Failure to comply with these rules and instructions may result in the Booking or Request being canceled`
// 3.4. Vehicle Information
export const bookingAndRequests3_4_p1 = `Prior to submitting a Request, Renters are required to provide complete and accurate vehicle information, which includes the following:`
export const bookingAndRequests3_4_ex_i = 'Make and Model'
export const bookingAndRequests3_4_ex_ii = 'Year'
export const bookingAndRequests3_4_ex_iii = 'Color'
export const bookingAndRequests3_4_ex_iv = 'License plate number'
export const bookingAndRequests3_4_ex_v = 'Size of the vehicle'

export const bookingAndRequests3_4_p2 = `Renters must ensure that the information provided is accurate. Only the vehicle specified in the Booking details is allowed to park in the Listing. Parking a different vehicle or providing inaccurate vehicle information is a breach of these terms and may result in the cancelation of the Booking.`

// 4. Payments
// 4.1 Payment Authorization
export const paymentsText4_1 = `To use the Services provided by Parket, Renters are required to maintain a valid credit card or debit card on file. By registering a payment method with us, you authorize Parket to charge your card for any Bookings made and associated fees as outlined in our fee schedule.`
// 4.2 Payment Processing
export const paymentsText4_2 = `Parket utilizes Stripe, a third-party payment processor, to securely handle credit and debit card transactions. By agreeing to these terms and using our Services, you also agree to be bound by Stripe’s terms of service and privacy policy, which govern the processing of your payment information. Please be aware that Parket does not store credit card details and that all payment data is managed by Stripe.`
// 4.3 Reoccuring Payments
export const paymentsText4_3 = `For Rolling Bookings, you authorize us to initiate recurring card charges according to the agreed-upon schedule (weekly or monthly). This authorization will continue until you or the Host cancels the Rolling Booking in accordance with our cancelation policy.`
// 4.4 Updates to Payment Method
export const paymentsText4_4 = `You agree to promptly update your card information on file in the event that your card expires or is replaced, or if you wish to change your designated payment method. Failure to maintain accurate and updated payment information may result in interruption of your access to the Services.`

// 5. Renter Cancelation Policy
// 5.1 General Renter Cancelation Policy
export const renterCancelationPolicyText5_1_p1 = `The renter cancelation policy can be broken up into the following three sections:`
// 5.1.i Grace Period Cancelation
export const renterCancelationPolicyText5_1_ex_i = `If you cancel your booking before the midpoint between the time of reservation and the start time of your booking, you will receive a full refund.`
// 5.1.ii Post-Grace Period Cancelation
export const renterCancelationPolicyText5_1_ex_ii = `If you cancel after the midpoint between the time of reservation and the start time, you will receive a 50% refund.`
// 5.1.iii After Booking Start Time
export const renterCancelationPolicyText5_1_ex_iii = `No refunds will be issued once a booking has commenced. If you need to cancel after your booking period has started and your vehicle is still located at the listing, you must remove your vehicle immediately.`
export const renterCancelationPolicyText5_1_p2 = `All cancelations must be processed through the Parket platform to ensure that they are recorded and handled appropriately. Failure to follow the proper cancelation procedures may result in the forfeiture of any potential refund.`
// 5.2 Cancelation of Rolling Bookings
export const renterCancelationPolicyText5_2 = `Renters who cancel a Rolling Booking after it has begun may continue to use the parking space until the end of the current billing period (week or month, depending on the booking agreement). No refunds will be provided for any remaining time in the current period post-cancelation.`
// 5.3 Host Cancelations
export const renterCancelationPolicyText5_3 = `In the event that a host cancels a confirmed Booking for any reason, the renter will be fully reimbursed for all charges associated with the canceled Booking. Parket will facilitate this reimbursement process to ensure it is completed promptly.`

// 6. At the Listing and Interacting with Hosts
// 6.1 Shared Spaces
export const listingAndHostInteractionsText6_1 = `The Listing property may be shared with other vehicles. Parket is not liable for any miscommunication or unavailability of the designated parking spot due to the presence of other vehicles. Should any issues or disputes arise during your booking or stay at the designated spot, you are initially required to contact the Host directly and attempt to resolve the matter amicably. If the issue persists after that you may file a report with us as outlined by our dispute policies.`
// 6.2 Your Responsibilities at the Listing
export const listingAndHostInteractionsText6_2 = `You must adhere strictly to all rules and access instructions provided by the Host. Alongside the rules set out by the Host, you agree to comply with the following:`
export const listingAndHostInteractionsText6_2_ex_i = `If the Host specifically designates an area for you to use, you may only park there.  You must ensure that no damage is done to the property or to any vehicles parked on the property. Renters are responsible for any damage they cause and may be subject to additional fees or legal action to recover the cost of repairs and/or compensation for damage.`
export const listingAndHostInteractionsText6_2_ex_ii = `Maintain the cleanliness of the parking space during your use. Do not leave trash or debris in the parking area. It is expected that the space will be left in the same condition as it was found.`
export const listingAndHostInteractionsText6_2_ex_iii = `Park your vehicle in a manner that does not obstruct other vehicles, entrances, or exits. Ensure that your vehicle fits within the designated area without encroaching on space reserved for others. Additionally, take care not to block access for other residents or neighbors in shared spaces.`
export const listingAndHostInteractionsText6_2_ex_iv = `The parking space must only be used for parking the vehicle specified in the booking. It is not permitted to use the parking space for storage, repairs, or any other purpose.`
// 6.3 Interacting With Hosts
export const listingAndHostInteractionsText6_3 = `As a Renter you are prohibited from soliciting or accepting side agreements or payments outside of the Parket platform. This includes, but is not limited to, future bookings, extensions of current bookings, and any transactions that bypass Parket fees and processes.`

// 7. Host Terms
// 7.1 Onboarding Process
export const hostOnboarding7_1 = `Users interested in renting out their property through Parket must first sign up as a Host. The onboarding process requires a few steps.`
export const hostOnboarding7_1_ex_i = `Users are required to submit detailed information about their parking space including setting its availability and rates.`
export const hostOnboarding7_1_ex_ii = `Users must provide personal and banking information to Stripe.`
export const hostOnboarding7_1_ex_iii = `Your application to become a Host will be reviewed by Parket. Concurrently, Stripe will perform its own verification process to validate your identity and financial information to ensure compliance with financial regulations.`

// 7.2 Listing Eligibility
export const listingEligibility7_2 = `Hosts must either own the parking space they list or have explicit authorization to rent it out. Hosts are responsible for understanding and complying with all local laws, codes, homeowners association (HOA) rules, and neighborhood policies that apply to renting out their parking spaces. This includes obtaining any necessary permits or licenses. Parket is not liable for any legal repercussions or penalties that Hosts may face as a result of non-compliance with local laws and regulations. Hosts are only allowed to maintain one Listing on the Parket platform. Hosts are responsible for ensuring that their parking space is easily accessible to Renters. This includes maintaining clear access routes, marking the parking area if necessary, providing any necessary instructions for accessing the space, and managing the use of the driveway or garage if it is a shared space with other neighbors or residents. Failure to provide accessible parking can result in the Listing being declined or removed from the Parket platform.`

// 7.3 Insurance Requirement
export const insuranceRequirement7_3 = `Parket does not provide insurance coverage for any transactions or for any damages to or losses of property. It is incumbent upon each Host to secure appropriate insurance coverage to mitigate risks associated with renting out their property. Ensure that your insurance policy covers the specific activities and risks involved in sharing your property with third-party users.`

// 7.4 Describing Your Space
export const describingYourSpace7_4 = `Hosts must clearly specify the type of parking space provided in their listing, indicating whether it is a driveway or a garage. If the parking space is large enough to accommodate multiple vehicles, Hosts may specify up to a maximum of five parking spots. Hosts must accurately measure the dimensions for each spot to ensure that they suitably fit the vehicles as described. When creating a title and description for the listing, Hosts must ensure that all information is accurate and truthful. Hosts are required to provide clear and simple instructions for accessing the parking space. These instructions should be easy to follow and help prevent any confusion for the Renter. Any rules added by the Host must be clear and unambiguous. The content in the title, description, access instruction, rules or any other text must not include profane, offensive, or inappropriate language.`

// 7.5 Image Guidelines
export const imageGuidelines7_5 = `Hosts must provide at least one clear image of their parking space, up to five max. All images must accurately reflect the actual condition and layout of the parking space. Images should be clear, well-lit, and focused to help Renters make informed decisions. Misleading images, whether through deceptive angles, edits, or outdated photos, are strictly prohibited and may result in the Listing being declined or removed. Images must not contain any profane, offensive, or inappropriate content. This includes, but is not limited to, offensive symbols, explicit language, or any imagery that could be considered discriminatory or in violation of community standards. Hosts must own or have the right to use all images they upload to the platform.`

// 7.6 Setting Availabilities
export const settingAvailabilities7_6 = `Hosts have the flexibility to choose the types of bookings they wish to accommodate on their Listing. This can include Standard Bookings or Rolling Bookings (Weekly and Monthly Bookings), or a combination of both. To accommodate Rolling Bookings, the Listing must be available 24/7. This ensures uninterrupted access for Renters who opt for this type of booking. Hosts offering Standard Bookings have the option to make their Listing available 24/7 or set custom weekly availability schedules. The Listing must be accessible to Renters during the predefined times each week set by the custom schedule if one is set. If a parking space is not available at the times previously committed to in the listing, the Host must promptly update their availability settings or deactivate their listing until availability can be guaranteed. Failure to maintain accurate availability may lead to removal of the Listing.`

// 7.7 Setting Rates
export const settingRates7_7 = `Hosts must set hourly, daily, weekly, and monthly rates for their listings. Parket calculates the final booking price using these rates to ensure fair pricing for all bookings.`

// 7.8 Stripe Verification
export const stripeVerification7_8_p1 = `Hosts are required to register with Stripe, our third-party payment processor. Registration is necessary for identity verification and to enable the receipt of payouts. During the registration process with Stripe, you will be asked to provide the following details:`
// 7.8 Examples
export const stripeVerification7_8_ex_i = 'Full name'
export const stripeVerification7_8_ex_ii = 'Email address'
export const stripeVerification7_8_ex_iii = 'Home address'
export const stripeVerification7_8_ex_iv = 'Phone number'
export const stripeVerification7_8_ex_v = 'Social Security number (or equivalent for non-U.S. hosts)'
export const stripeVerification7_8_ex_vi = 'Payment method details (for receiving payouts)'

export const stripeVerification7_8_p2 = `Stripe’s verification process can take up to a day or more, depending on various factors. Parket is not responsible for the verification process carried out by Stripe. By providing your information to Stripe for verification, you are agreeing to abide by Stripe’s terms and conditions. It is important that you review these terms carefully as they govern your use of Stripe's services and your relationship with Stripe regarding payment processing.`

// 7.9 Parket Approval
export const parketApproval7_9 = `We’ll review your application and decide on its approval. Parket reserves the right to reject applications at our discretion without providing a reason. Once approved, your parking space will be listed on our platform, making it available to Renters. Parket retains the right to remove any listing from the platform at any time if the Host fails to comply with our terms and conditions.`

// 8. Managing Your Listing
// 8.1 Updating Information About Your Listing
export const managingYourListing8_1_p1 = `Hosts are permitted to make changes to various aspects of their listing, including, but not limited to:`
// 8.1 Examples
export const managingYourListing8_1_ex_i = `Title`
export const managingYourListing8_1_ex_ii = `Amenities`
export const managingYourListing8_1_ex_iii = `Number of parking spots`
export const managingYourListing8_1_ex_iv = `Size of each spot`
export const managingYourListing8_1_ex_v = `Types of bookings accommodated`
export const managingYourListing8_1_ex_vi = `Availability`
export const managingYourListing8_1_ex_vii = `Rates`
export const managingYourListing8_1_ex_viii = `Images`
export const managingYourListing8_1_ex_ix = `Description`
export const managingYourListing8_1_ex_x = `Access instructions`
export const managingYourListing8_1_ex_xi = `Rules`

export const managingYourListing8_1_p2 = `Hosts cannot edit the location of their listing directly. If there is an issue with the address of the listing, please contact us for assistance. Hosts must ensure that any changes to their Listing are updated on our platform as soon as possible to maintain accurate and reliable information for Renters.`

// 8.2 Deactivating and Activating Your Listing
export const managingYourListing8_2 = `Hosts may deactivate their Listing, which will remove the Listing from view by Renters on the platform. Hosts are not permitted to deactivate their listing if there are any active bookings. Hosts can reactivate their listing whenever they wish, provided there are no outstanding restrictions or issues that need to be resolved. Upon reactivation, the listing will again be visible to Renters and available for new bookings.`

// 8.3 Deleting Your Listing
export const managingYourListing8_3 = `Hosts have the option to permanently delete their Listing from the platform. This action is irreversible and means the Listing will be completely removed from Parket. If a host chooses to delete their Listing and later decides to offer the parking space again, they must undergo the entire approval process anew. The previously deleted Listing cannot be reinstated and must be treated as a new submission.`

// 9. Accepting Requests and Managing Bookings
// 9.1 Manually Accepting Requests
export const acceptingRequests9_1_p1 = `Hosts have the option to manually accept Requests from Renters. Upon accepting a Request, the process for the transfer of funds from the Renter to the Host will be initiated. Hosts are responsible for thoroughly reviewing all the details provided in the booking request. This includes ensuring that:`
export const acceptingRequests9_1_ex_i = `They can accommodate the booking within the specified times.`
export const acceptingRequests9_1_ex_ii = `The parking space is suitable for the Renter’s vehicle in terms of size and type.`

export const acceptingRequests9_1_p2 = `While Parket provides tools to help manage accepting Requests and avoid scheduling conflicts, Hosts are ultimately responsible for ensuring that their Listing’s availability is accurately reflected and that no conflicts arise.`

// 9.2 Auto-Accepting Requests
export const acceptingRequests9_2 = `Hosts may choose to enable a setting that allows for the automatic acceptance of all Requests. While we assist in managing and mitigating potential conflicts, it is the ultimate responsibility of the Host to prevent any conflicts with their availability or space. Hosts are obligated to review the details of each Booking and ensure that the terms of Booking are clear.`

// 9.3 Your Responsibilities as a Host
export const acceptingRequests9_3 = `As a Host you must:`
export const acceptingRequests9_3_ex_I =
    'Ensure that your listing is clean and free from any obstructions that could prevent Renters from parking. The parking space must be maintained in a condition that is ready for immediate use by the Renter.'
export const acceptingRequests9_3_ex_II =
    'Honor all confirmed Bookings for the full duration of the Booking period. Once a Booking is accepted, you must not cancel it unless absolutely necessary, in which case you must follow the cancelation policy.'
export const acceptingRequests9_3_ex_III =
    'Interactions with Renters must be conducted in a professional and courteous manner at all times. This includes communications conducted through our platform and any direct interactions.'
export const acceptingRequests9_3_ex_IV =
    'You must be available to respond promptly to any inquiries or questions from Renters. All inquiries should be handled in a manner that is considerate and helpful.'
export const acceptingRequests9_3_ex_V =
    "All amenities listed as available in your parking space description must be present and functional for the Renter's use throughout the duration of their booking."

// 9.4 Dealing with Multiple Cars
export const acceptingRequests9_4 = `If your listing accommodates multiple vehicles, you must ensure that all cars can enter, park, and exit the space easily and without obstruction. It is imperative that each vehicle has adequate space and access. Hosts must provide clear and detailed instructions for every vehicle entering your space. This includes entry, parking, and exit procedures to prevent any confusion or mishaps. If necessary, you should mark spaces clearly for designated vehicles or sizes to optimize the organization and use of the parking area. Parket is not liable for any issues, including damage or disputes, that arise from multiple cars parking in your space. As the Host, it is your responsibility to manage the space effectively and to ensure that all guidelines and instructions are clearly communicated and adhered to by the Renters.`

// 9.5 Damage to Person or Property
export const acceptingRequests9_5 = `Parket is not liable for any damages to or theft from your property incurred during the use of our platform. All parking transactions facilitated through Parket are entered into at your own risk. While Parket facilitates connections between Hosts and Renters, we do not guarantee the security or safety of the parking spaces listed on our platform. It is the sole responsibility of the Host to secure and protect their property. If damage occurs to your property by a Parket user, you agree to first seek resolution directly with the involved user. Should you be unable to resolve the issue amicably, you may contact Parket to file a report. However, Parket's role in such disputes is limited and does not include any form of mediation or financial compensation.`

// 10. Host Cancelation Policy
// 10.1 General Host Cancelation Policy
export const hostCancelationPolicy10_1 = `Hosts are able to cancel Bookings at any time except for the last 15 minutes of a Booking. Hosts do not incur direct penalties for a single or infrequent cancelation of Bookings. However, If a pattern of repeated cancelations is observed, it may lead to penalties, including but not limited to warnings, temporary suspensions, or even permanent deactivation of the host account. All cancelations must be processed through the Parket platform to ensure that they are recorded and handled appropriately. Failure to follow the proper cancelation procedures may result in the forfeiture of any potential refund.`

// 10.2 Cancelation of Rolling Bookings
export const hostCancelationPolicy10_2 = `Hosts who cancel a rolling booking must allow the Renter to continue to use the parking space until the end of the current billing period (week or month, depending on the booking agreement). Failure to adhere to this policy will result in penalties, which include, but are not limited to the suspension or termination of your Host account.`

// 10.3 Renter Cancelations
export const hostCancelationPolicy10_3 = `If a Renter cancels a booking before the start date, they are entitled to a refund according to the Renter cancelation Policy outlined in Section 4. The payment initially designated for the Host will be removed from their payout due to the cancelation. Renters who cancel after the booking period has commenced will not receive a refund. In this case, the Host will retain any payments received for the Booking period, even if the Renter does not utilize the space for the full duration originally booked.`

// 11. Payouts
// 11.1 Payout Schedule
export const payouts11_1 = `Hosts earn an amount for each booking they accommodate. These amounts are grouped into Payouts. Payouts are transferred to the Host's chosen payment method via Stripe every Tuesday at 9 AM. The arrival time of funds in your account depends on the processing time associated with your chosen payout method.`

// 11.2 Handling Issues with Payouts
export const payouts11_2 = `If you encounter any discrepancies or issues with your payouts, such as incorrect payout amounts, payouts not arriving as scheduled, or any other concerns related to the funds you have accrued, you are required to notify us immediately. Please contact us at contact@joinparket.com with a detailed description of the issue. We will investigate the matter promptly and strive to resolve any errors or delays in your payouts to ensure you receive the correct amounts owed to you.`

// 11.3 Updating Your Bank Account Information
export const payouts11_3 = `You agree to promptly update your bank account information on file in the event that your account details change, or if you wish to change your designated payment method. Failure to maintain accurate and updated payment information may result in interruption of your access to the Services.`

// 12. General Terms
// 12.1 Accounts - Registration
export const accountsRegistration12_1 = `To access and use the services provided by Parket, all users must register an account with us. During the registration process, you must provide current, complete, and accurate information as prompted. This includes your full name, email address, and any other personal information requested. Each User is allowed only one registration with Parket. Creating multiple accounts is prohibited and may result in termination of all related accounts. Users must be 18 years of age or older to register and engage with Parket’s services. By registering, you confirm that you meet the minimum age requirement. For any concerns regarding the collection, use, and protection of your personal data, please refer to our Privacy Policy.`

// 12.2 Accounts - Termination
export const accountsTermination12_2 = `Parket reserves the right to terminate your account and access to the Services at any time without notice if we believe that you have breached any of these Terms of Service.`

// 13. Fee Structure
// 13.1 How we set prices using Host’s rates
export const feeStructure13_1 = `Hosts set their own hourly, daily, weekly, and monthly rates for their Listing. We use these rates as the basis for calculating the cost of bookings depending on the duration of the stay booked by a Renter. The rates set by the Host act as a cap and the total cost for any single day, week, or month will not exceed the daily, weekly, or monthly rate set by the Host, respectively. While base rates are set by the Hosts, Parket may apply dynamic pricing strategies under certain circumstances such as high demand, special events, or seasonal fluctuations.`

// 13.2 Defining a Parket day
export const feeStructure13_2 = `A "Parket day" begins at 6 AM. This definition is used to determine the start and end of a single rental day for booking and billing purposes. If a Booking period crosses the 6 AM line, they’ll be charged a daily rate to ensure that Hosts are not shortchanged for bookings that extend into another day. Same day Bookings are an exception to this rule.`

// 13.3 Breakdown of fees
export const feeStructure13_3 = `Each transaction can be broken into a subtotal and service fee. The subtotal is the amount we derive using Host rates. The service fee is a fixed percentage of the subtotal that we collect on every transaction.`

// 14. Overstay Policy
// 14.1 Renter Expectations
export const overstayPolicy14_1 = `Renters are expected to immediately vacate the parking space by the end of their booked time. In the event of an unforeseen delay, a grace period of 15 minutes is allowed. This grace period is meant to accommodate minor emergencies or unavoidable delays.`

// 14.2 Communicating with the Renter and Parket
export const overstayPolicy14_2 = `If the Renter has not vacated the parking space after the grace period, the Host should first attempt to contact the Renter to inquire about their delay. Communication can often resolve the issue without further action. Should the Renter fail to respond or vacate the space promptly after being contacted, the Host must report the overstay to Parket. We will notify the renter of their overstay and remind them of their obligations under the terms of their booking. While Parket will make every effort to contact the renter and resolve the situation, we cannot guarantee immediate or specific action.`

// 14.3 Host Actions Post-Grace Period
export const overstayPolicy14_3 = `If the Renter still has not vacated the space 15 minutes after the grace period has ended, the Host may take appropriate actions, which should be reasonable and lawful. This may include removing the vehicle, but hosts must ensure that any actions taken comply with local laws and do not involve force or illegal measures.`

// 14.4 Overstay Liability
export const overstayPolicy14_4 = `Parket is not responsible for any damages, costs, or legal implications incurred due to overstays. Hosts and renters are encouraged to handle overstays amicably, but hosts must understand their legal rights and responsibilities when taking action.`

// 15. Disputes
export const disputes15 = `In the event of a dispute between you and another User, you are required to attempt to resolve the issue directly with the other party first. If direct mediation does not lead to a resolution, you may file a report with us. Please provide detailed information about the dispute, including communication between you and the other user, and any other relevant details that could help us understand the situation better. Upon receiving a report, we will review the details and may intervene to facilitate further discussion. However, Parket cannot guarantee any specific actions, outcomes, or compensation as a result of this process.`

// 16. Content
// 16.1 Content Policy
export const contentPolicy16_1 = `Users may upload content to the Parket platform, including but not limited to images of themselves, listing images, car images, text descriptions, and titles. You retain ownership of the content you provide. By posting content on Parket, you grant us a non-exclusive, worldwide, royalty-free, perpetual, sublicensable, and transferable license to access, use, store, copy, modify, create derivative works from, distribute, publish, transmit, stream, broadcast, and otherwise exploit such content in any manner. This license is solely for the purpose of operating, promoting, and improving our services, and to develop new ones.`

// 16.2 Content Restrictions
export const contentRestrictions16_2 = `You must ensure that any content you provide is accurate and not misleading. Misrepresentation through content can lead to user dissatisfaction and potential legal issues. You agree not to upload content that is offensive, indecent, or objectionable. Parket reserves the right to review and remove any content that violates our community standards or terms of service without notice. You are responsible for ensuring that the content you upload does not violate the intellectual property rights or privacy rights of any third party. You should have the necessary rights and permissions for all the content you upload.`

// 17. Application License
// 17.1 Grant of License
export const applicationLicense17_1 = `Parket grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the application solely for your personal, non-commercial purposes strictly in accordance with the terms of this agreement.`

// 17.2 License Restrictions
export const applicationLicense17_2 = `You agree not to, and you will not permit others to:`
export const applicationLicense17_2_ex_I =
    'License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the application or make the platform available to any third party.'
export const applicationLicense17_2_ex_II = 'Modify, make derivative works of, disassemble, decrypt, reverse compile, or reverse engineer any part of the application.'
export const applicationLicense17_2_ex_III = 'Remove, alter, or obscure any proprietary notice (including copyright and trademark notices) of Parket or its affiliates, partners, and licensors.'

// 17.3 Copyright
export const applicationLicense17_3 = `The Parket app and all intellectual property rights therein, including but not limited to software, services, text, graphics, logos, images, as well as the trademark "Parket", are owned by or licensed to Parket. This license does not grant you any rights to use the proprietary logos, service marks, or trademarks.`

// 17.4 License Termination
export const applicationLicense17_4 = `This license shall remain in effect until terminated by you or Parket. Parket may, in its sole discretion, at any time and for any or no reason, suspend or terminate this license with or without prior notice. This license will terminate immediately, without prior notice from Parket, if you fail to comply with any provision of these terms. Upon termination, you must cease all use of the app and delete all copies of the app from your mobile device and account.`

// 17.5 License Amendment
export const applicationLicense17_5 = `Parket reserves the right to modify, suspend, or discontinue, temporarily or permanently, the application or any service to which it connects, with or without notice and without liability to you.`

// 18. Use Restrictions
// 18.1 Unauthorized Data Extraction
export const useRestrictions18_1 = `You may not engage in any data mining, scraping, or similar data gathering or extraction methods on any part of our website or app. The use of robots or other automated data extraction tools is strictly forbidden.`

// 18.2 Solicitation
export const useRestrictions18_2 = `It is prohibited to solicit other users of Parket, whether for commercial purposes or otherwise. You may not use any information obtained from the platform to harass, abuse, or harm another person, or to contact, advertise to, solicit, or sell to any user without their prior explicit consent.`

// 18.3 Exploitation of Services
export const useRestrictions18_3 = `You are prohibited from exploiting any part of the Parket service for any unauthorized commercial purpose. You may not use Parket’s services or any part of them to transmit or aid in the transmission of any unsolicited advertising, promotional materials, or other forms of solicitation.`

// 18.4 Unauthorized Access
export const useRestrictions18_4 = `Attempting to gain unauthorized access to the Parket’s computer systems or engaging in any activity that disrupts, diminishes the quality of, interferes with the performance of, or impairs the functionality of, the Services or the Parket platform is prohibited.`

// 19. Reporting Violations
export const reportingViolations19 = `If you believe that someone poses an imminent danger or harm to you or others, contact local law enforcement immediately. If you suspect that any User you have interacted with on Parket has violated our Terms of Service, you must report this to us.`

// 20. Indemnity
export const indemnity20 = `You agree to indemnify, defend, and hold harmless Parket, its affiliates, officers, directors, employees, agents, licensors, and service providers from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to:`
export const indemnity20_ex_I = 'Your violation of these terms and conditions.'
export const indemnity20_ex_II =
    'Your use of the Parket platform, including, but not limited to, your user content, third-party sites, any use of Parket’s content, services, and products other than as expressly authorized in these terms and conditions.'
export const indemnity20_ex_III = 'Your use of any information obtained from Parket.'
export const indemnity20_ex_IV = 'Any disputes or issues between you and any third party, including other users and hosts.'
export const indemnity20_ex_V = 'Your violation of any rights of another, including but not limited to intellectual property rights.'

export const indemnity20_note = `Parket will provide you with prompt notice of any such claim, suit, or proceeding that is subject to this indemnification. Parket reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section, in which case you will cooperate with any reasonable requests to assist Parket’s defense of such matter.`

// 21. Arbitration
// 21.1 Agreement to Arbitrate
export const arbitration21_1 = `You agree that any dispute, claim, or controversy arising out of or relating to your use of the Parket platform, these terms, or the breach, termination, enforcement, interpretation, or validity thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by arbitration in Delaware, except that Parket may seek injunctive or other equitable relief to protect its intellectual property rights in any court of competent jurisdiction.`

// 21.2 Arbitration Rules
export const arbitration21_2 = `The arbitration will be administered by the American Arbitration Association (AAA) in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (AAA Rules) then in effect, except as modified by this arbitration agreement.`

// 22. Class Action Waiver
export const classActionWaiver22 = `You and Parket agree that each may bring claims against the other only in your or its individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding. Further, unless both you and Parket agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.`

// 23. Severability
export const severability23 = `If any provision of these Terms of Service is held to be invalid, illegal, or unenforceable by a court or other tribunal of competent jurisdiction, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect. This ensures that even if one part of the agreement is found to be problematic, the rest of the terms remain operational and binding.`
