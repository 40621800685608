import React from 'react'
import './Help.css'
import emailjs from '@emailjs/browser'
import { FaChevronDown } from 'react-icons/fa'
import { IoIosArrowRoundDown } from 'react-icons/io'
import { faqText } from './faq-text'
import { MdEmail } from 'react-icons/md'
import { IoChatbubbleEllipses, IoCall } from 'react-icons/io5'

export default function Landing({ isMobile }) {
    // Initialize emailjs service
    // https://www.emailjs.com/
    React.useEffect(
        () =>
            emailjs.init({
                // Public key is OK to expose
                // https://www.emailjs.com/docs/faq/is-it-okay-to-expose-my-public-key/
                publicKey: '9jFNyRlyeX9-cbAfK',
                limitRate: {
                    // Set the limit rate for the application
                    id: 'app',
                    // Allow 1 request per 30s
                    throttle: 30000,
                },
            }),
        []
    )

    // Object that looks like {0: false, 1: false, 2: false, ...}
    const [selectedQuestions, setSelectedQuestions] = React.useState(
        faqText.reduce((obj, _, index) => {
            obj[index] = false
            return obj
        }, {})
    )

    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: '',
    })

    // Update contact form field updates
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })
    }

    // Submit contact form
    const handleSubmit = (e) => {
        e.preventDefault()

        // Follows EmailJS Template setup
        // https://www.emailjs.com/docs/user-guide/creating-email-templates/
        const emailData = {
            from_name: `${formData.firstName} ${formData.lastName}`,
            user_email: formData.email,
            subject: formData.subject,
            message: formData.message,
        }
        // EmailJS Service: https://dashboard.emailjs.com/admin
        emailjs
            .send('service_ratwwxb', 'template_f1fwnto', emailData)
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text)
                alert('Your message has been sent!\n\nPlease give us 24 hours to respond.')
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    subject: '',
                    message: '',
                })
            })
            .catch((err) => {
                console.error('Failed to send email:', err)
                alert('Failed to send your message. Please try again later.')
            })
    }

    // Handle FAQ question click
    const handleOnClickQuestion = (index) => {
        setSelectedQuestions((prevState) => {
            return {
                ...prevState,
                [index]: !prevState[index],
            }
        })
    }

    return (
        <div className="help-page-container">
            <div className="main-background">
                <div className="contact-container">
                    <h1 className="contact-header">Contact Us</h1>
                    <p className="contact-text">
                        <IoCall className="help-contact-icon" /> <a href="tel:+9295957275">(929) 595-7275</a>
                    </p>
                    <p className="contact-text">
                        <IoChatbubbleEllipses className="help-contact-icon" /> <a href="sms:9295957275">(929) 595-7275</a>
                    </p>
                    <p className="contact-text">
                        <MdEmail className="help-contact-icon" />{' '}
                        <span>
                            <a
                                href="mailto:contact@joinparket.com"
                                target="_blank"
                                rel="noreferrer">
                                contact@joinparket.com
                            </a>
                        </span>
                    </p>
                </div>
                <div className="faq-container">
                    <h1 className="faq-header">Frequently Asked Questions</h1>
                    <div>
                        {faqText.map((item, index) => {
                            // Add links to the appropriate placeholders in the answer text
                            let answer = []
                            if (item?.links) {
                                const parts = item.answer.split(/{link\d+}/)
                                parts.forEach((part, index) => {
                                    answer.push(part)
                                    if (index < item?.links.length) {
                                        const link = item?.links[index]
                                        answer.push(
                                            <span className="faq-link">
                                                <a
                                                    href={link.link}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    {link.placeholder}
                                                </a>
                                            </span>
                                        )
                                    }
                                })
                            } else {
                                answer.push(item.answer)
                            }

                            return (
                                <div className="faq-item">
                                    <div
                                        onClick={() => handleOnClickQuestion(index)}
                                        className="faq-question-row">
                                        <h2 className="faq-question">{item.question}</h2>
                                        {isMobile ? (
                                            <IoIosArrowRoundDown className={`dropdown-icon ${selectedQuestions[index] ? 'rotated' : ''}`} />
                                        ) : (
                                            <FaChevronDown className={`dropdown-icon ${selectedQuestions[index] ? 'rotated' : ''}`} />
                                        )}
                                    </div>
                                    <p
                                        className={selectedQuestions[index] ? 'faq-answer-open faq-display-linebreak' : 'faq-answer-close'}
                                        style={selectedQuestions[index] ? { maxHeight: 500, opacity: 1, marginTop: 20 } : { maxHeight: 0, opacity: 0, marginTop: 0 }}>
                                        {answer}
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Contact Form */}
            <div className="contact-form-container">
                <h2>Contact Form</h2>
                <form
                    onSubmit={handleSubmit}
                    className="contact-form">
                    <div className="name-row">
                        <label>
                            First Name
                            <input
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Last Name
                            <input
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <label>
                        Email
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Subject
                        <select
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required>
                            <option
                                value=""
                                disabled
                                hidden>
                                Select an option
                            </option>
                            <option value="Question">Question</option>
                            <option value="Issue">Issue</option>
                            <option value="Request Account Deletion">Request Account Deletion</option>
                        </select>
                    </label>
                    <label>
                        Message
                        <textarea
                            name="message"
                            placeholder="How can we assist you?"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
}
